import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import axiosInstance from '../../../../axiosHelper/axios';
import { removeSpaces } from '../../../../utils/functions';
import { useWeb3React } from '@web3-react/core';
import { useNavigate } from 'react-router-dom';
import SelectPlanetDropDown from './SelectPlanetDropDown.js';
import SelectTags from './SelectTags';
import { generateSignedPayload } from '../../../../utils/functions';
import useData from '../../../../utils/dataContext';

//ASSETS
// import SmallDownArrow from '../../../../images/SmallDownArrow.svg';
// import Smile from '../../../../images/Smile.svg';
// import ImageUpload from '../../../../images/ImageUpload.svg';
import profileImage from '../../../../images/profile.svg';
import { ReactComponent as CloseButtom } from '../../../../images/CloseButton.svg';
import './ProposalModal.css';

interface ModalProps {
    account_id: string | null | undefined;
    status: boolean;
    modalTagOptions: any;
    handleModal: any;
    // handleProposalSubmit: any
}

const ProposalModal: React.FC<ModalProps> = ({
    account_id,
    status,
    modalTagOptions,
    handleModal,
}) => {
    const { allPlanetBalance } = useData();

    const allPlanetOptions: any = [
        { value: 'UPDAO', label: 'UPDAO' },
        { value: 'Mercury', label: 'Mercury' },
        { value: 'Venus', label: 'Venus' },
        { value: 'Earth', label: 'Earth' },
        { value: 'Moon', label: 'Moon' },
        { value: 'Mars', label: 'Mars' },
        { value: 'Jupiter', label: 'Jupiter' },
        { value: 'Saturn', label: 'Saturn' },
        { value: 'Uranus', label: 'Uranus' },
        { value: 'Neptune', label: 'Neptune' },
        { value: 'Pluto', label: 'Pluto' },
    ];

    //filters planet drop down based on if you have tokens for that planet
    let planetOptions: any = allPlanetOptions.filter(
        (planet: any, index: number) => {
            if (planet.value === 'UPDAO') {
                return planet;
            } else if (allPlanetBalance[index - 1] > 0) {
                return planet;
            }
        }
    );

    //To get user information
    const { isActive, account, connector } = useWeb3React();
    const {provider} = connector
    let params: any = useParams();
    const navigate = useNavigate();
    //State for Form
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [selectedPlanet, setSelecteedPlanet] = useState<any>(null);
    const [tags, setTags] = useState<any>([]);
    //Error handling for form
    const [submissionErr, setSubmissionErr] = useState<boolean>();
    const [fillProposalErr, setFillProposalErr] = useState<boolean>();

    let providerInstance = isActive && provider ? provider : undefined;
    useEffect(() => {
        let getCurrPlanet = null;
        for (let i = 0; i < allPlanetOptions.length; i++) {
            if (allPlanetOptions[i].value === params.planet_name) {
                getCurrPlanet = allPlanetOptions[i];
            }
        }
        setSelecteedPlanet(getCurrPlanet);
    }, [params.planet_name]);

    const handleCloseAndClearState = () => {
        handleModal(status);
        setTitle('');
        setDescription('');
        setFillProposalErr(false);
        setSubmissionErr(false);
    };

    const submitProposal = async (
        title: string,
        description: string,
        tags: any,
        selectedPlanet: string
    ) => {
        let adjustedTags = tags.map((e: any) => {
            return e.value;
        });
        const titleNoSpaces = removeSpaces(title);
        const descriptionNoSpaces = removeSpaces(description);

        const msgBody = {
            author: account,
            title,
            description,
            tags: adjustedTags,
            planet: selectedPlanet,
            signerAddress: account,
        };

        if (titleNoSpaces !== '' && descriptionNoSpaces !== '') {
            const signature = await generateSignedPayload(providerInstance, msgBody);
            axiosInstance
                .post('proposals/create', {
                    msgBody,
                    signature,
                })
                .then((res) => {
                    setTitle('');
                    setDescription('');
                    // handleProposalSubmit()
                    handleModal(status);
                    navigate(`../${res.data.planet}/${res.data._id}`);
                    setFillProposalErr(false);
                })
                .catch((err) => {
                    console.error('Submission Error: ', err);
                    setSubmissionErr(true);
                });
        } else {
            setFillProposalErr(true);
            setSubmissionErr(false);
        }
    };

    if (status) {
        return (
            <div className="create-proposal-background">
                <div className="create-proposal-modal">
                    <form className="create-proposal-form">
                        <div className="cpf-top">
                            <div>
                                <img
                                    className="cpf-modal-profile"
                                    src={profileImage}
                                    alt="profile"
                                />
                                <div className="cpf-account-id">
                                    {account_id}
                                </div>
                            </div>
                            <div
                                className="cpf-close-modal cpf-x-button"
                                onClick={() => handleCloseAndClearState()}
                            >
                                <CloseButtom className="cpf-tag-close cpf-x-button" />
                            </div>
                        </div>
                        <input
                            id="cpf-title-input"
                            className="cpf-inputs"
                            type="text"
                            placeholder="Add a title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            maxLength={300}
                        />
                        <textarea
                            id="cpf-description-input"
                            className="cpf-inputs"
                            placeholder="Tell us about your proposal..."
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <div className="cpf-bottom-mid">
                            {/* <ModalTags tag={tag} setTag={setTag} tags={tags} setTags={setTags}/> */}
                            {/* <div className='cpf-right-side'>
                            <img className='cpf-upload-image button-pointer' src={ImageUpload} alt='upload file'/>
                            <img className='cpf-upload-image button-pointer' src={Smile} alt='smiley face'/>
                        </div> */}
                            <div className="cpf-bottom-mid-text">Tags: </div>
                            <SelectTags
                                myOptions={modalTagOptions}
                                setTags={setTags}
                            />
                            {/* <TagsDropDown
                            mobile={false}
                            filterStatusOptions={tagOptions}
                            setStatusOption={setTag}
                        /> */}
                        </div>
                        <div className="cpf-bottom">
                            <div className="cpf-bottom-left">
                                <div className="cpf-bottom-left-text">
                                    Proposal for:{' '}
                                </div>
                                {/* <div className='cpf-drop-menu'> */}
                                {/* <div className='cpf-select-planet'>  
                                    <div>{selectedPlanet}</div> */}
                                {/* <img src={SmallDownArrow} alt="select planet"/>    */}
                                {/* </div> */}
                                <SelectPlanetDropDown
                                    mobile={false}
                                    planetOptions={planetOptions}
                                    setSelecteedPlanet={setSelecteedPlanet}
                                    currPlanet={params.planet_name}
                                />
                                {/* <ModalDropDown 
                                status={dropMenu} 
                                setStatus={setSelecteedPlanet} 
                                handleDropMenu={handleDropMenu} 
                                dropMenu={dropMenu} 
                                dropdownlist={planets}/>                          */}
                                {/* </div> */}
                            </div>
                            <div
                                className="sumbit-proposal-button button-pointer"
                                onClick={() =>
                                    submitProposal(
                                        title,
                                        description,
                                        tags,
                                        selectedPlanet.value
                                    )
                                }
                            >
                                Submit Proposal
                            </div>
                        </div>
                    </form>
                    {submissionErr ? (
                        <div className="proposal-error-message">
                            There was an error submitting your proposal.
                        </div>
                    ) : null}
                    {fillProposalErr ? (
                        <div className="proposal-error-message">
                            Please fill in all sections above.
                        </div>
                    ) : null}
                </div>
            </div>
        );
    } else {
        return <div style={{ display: 'none' }}></div>;
    }
};

export default ProposalModal;
