import axios from "axios";
require("dotenv").config();

//can propobably move this file to utils since it is by itself
const baseURL = process.env.REACT_APP_BACKEND_BASE_URL || 'https://zonked-coal-production.up.railway.app/';
console.log(process.env.REACT_APP_BACKEND_BASE_URL);
console.log(baseURL);

let headers = {
  'Content-Type': 'application/json',
};

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
  timeout: 10000,
  responseType: "json",
});

export default axiosInstance;
