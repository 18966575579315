import React from "react";
import "./overviewDashboard.css";
import ConnectWidget from "../connectWidget/connectWidget";
import ProposalsWidget from "../proposalsWidget/proposalsWidget";
// import useData from "../../../utils/dataContext";
import { useWeb3React } from "@web3-react/core";
import MetaheroPriceGraph from "../metaheroPriceGraph/metaheroPriceGraph";

import tweeticon from "../../../images/updaobird.svg";
import TweetContainer from "../tweetEmbed/tweetContainer";
import ProposalTabs from "../proposalsTab/proposalsTab";

// const types = ["Closing soon", "Recently Enacted", "Your proposals"];

const Overview: React.FC = () => {
    const { isActive } = useWeb3React();
    // const [tabActive, setTabActive] = useState<any>(types[0]);

    return (
        <section className="dashboard">
            {/* left */}
            <div id="left">
                {!isActive ? <ConnectWidget /> : <ProposalsWidget />}
                <div className="left-card">
                    <MetaheroPriceGraph />
                </div>
            </div>

            {/* middle */}
            <div id="middle">
                <p className="proposals-text">Proposals</p>
                <ProposalTabs />
            </div>

            {/* right */}
            <div id="right">
                <div className="right-header">
                    <div className="twitter-header">
                        <img src={tweeticon} alt="twitter icon" />
                        <div className="twitter-header-title">UPDAO</div>
                        <p className="handle">@UPDAO_</p>
                    </div>

                    {/* <p className="handle">@pixelvault_</p> */}
                    <TweetContainer />
                </div>
            </div>
            <div className="left-card price-graph-2">
                <MetaheroPriceGraph />
            </div>
        </section>
    );
};

export default Overview;
