import React, { useEffect, useState } from 'react';
import './ProposalsTab.css';
import ProposalsTabNav from './proposalsTabNav';
import ProposalsTabContent from './proposalsTabContent';
import ProposalCommentBox from '../../proposalCommentBox/proposalCommentBox';
import PaginationBar from '../../ProposalsSection/paginationBar/paginationBar';
import profile from '../../../images/profile.svg';
// import greenstatus from '../../../images/status.svg';
import axiosInstance from '../../../axiosHelper/axios';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Rings } from 'react-loader-spinner';
import {
    truncate,
    truncateText,
    getTimeRelativeToNow,
} from '../../../utils/functions';

const ProposalTabs: React.FC = () => {
    const [activeTab, setActiveTab] = useState('tab1');
    const [overviewProposals, setOverviewProposals] = useState<Object[]>([]);
    const [activeTabCall, setActiveTabCall] = useState<string>('closing');
    const [isLoading, setIsLoading] = useState<boolean>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const { account, isActive } = useWeb3React<Web3Provider>();

    useEffect(() => {
        getOverviewProposals(activeTabCall, pageNumber);
    }, [activeTabCall, pageNumber]);

    const getOverviewProposals = (
        proposal_status: string,
        currPageSize: number
    ) => {
        setIsLoading(true);
        let target = '';

        if (proposal_status === 'closing') {
            target =
                'proposals/?sortBy=endAt&sortDir=asc&status=Active&status=Draft&status=Quorum';
        } else if (proposal_status === 'enacted') {
            target =
                'proposals/?sortBy=endAt&sortDir=desc&status=Closed&postStatus=Enacted';
        } else if (proposal_status === 'proposals') {
            // your proposals
            target = `/proposals/?authorSignerAddress=${account}`;
        } else if (proposal_status === 'pending') {
            target =
                'proposals/?sortBy=endAt&sortDir=desc&status=Closed&postStatus=Pending%20Enactment';
        }

        let overviewParameters = {
            params: {
                pageSize: 10,
                page: currPageSize,
            },
        };
        axiosInstance
            .get(target, overviewParameters)
            .then((res) => {
                const allOverviewProposals = res.data;
                setOverviewProposals(allOverviewProposals);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log('ERROR: ', err);
                setOverviewProposals([]);
            });
    };

    return (
        <div className="tabs">
            <ul className="toggle">
                <ProposalsTabNav
                    title="Closing Soon"
                    id="tab1"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setActiveTabCall={setActiveTabCall}
                />
                <ProposalsTabNav
                    title="Recently Enacted"
                    id="tab2"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setActiveTabCall={setActiveTabCall}
                />
                <ProposalsTabNav
                    title="Pending Enactment"
                    id="tab3"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setActiveTabCall={setActiveTabCall}
                />
                {isActive ? (
                    <ProposalsTabNav
                        title="Your Proposals"
                        id="tab4"
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        setActiveTabCall={setActiveTabCall}
                    />
                ) : null}
            </ul>
            <div className="outlet">
                <ProposalsTabContent
                    id="proposals-output"
                    activeTab={activeTab}
                >
                    {isLoading ? (
                        <div className="loading-vote">
                            <Rings
                                height={75}
                                color="#fff"
                                ariaLabel="loading-indicator"
                            />
                            Loading proposals
                        </div>
                    ) : (
                        <>
                            {overviewProposals.map(
                                (proposal: any, index: number) => (
                                    <ProposalCommentBox
                                        key={index}
                                        proposalId={proposal._id}
                                        planet={proposal.planet}
                                        middlePadding={true}
                                        creatorAddress={truncate(
                                            proposal.authorSignerAddress
                                        )}
                                        creatorImg={profile}
                                        createdAt={getTimeRelativeToNow(
                                            proposal.createdAt
                                        )}
                                        expiresAt={getTimeRelativeToNow(
                                            proposal.endAt
                                        )}
                                        title={truncateText(proposal.title, 45)}
                                        // commentTotal={proposal.comments.length}
                                        votes={proposal.votes}
                                        status={proposal.status}
                                        quorumAmount={Object.values(
                                            proposal.updaoStatus
                                        )}
                                        updaoVotingTotals={
                                            proposal.status === 'Closed'
                                                ? proposal.finalUpdaoVotes
                                                : proposal.updaoVoteTotals
                                        }
                                        tags={proposal.tags}
                                    />
                                )
                            )}
                            {overviewProposals.length >= 10 ||
                            pageNumber !== 1 ? (
                                <PaginationBar
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}
                                />
                            ) : null}
                        </>
                    )}
                </ProposalsTabContent>
            </div>
        </div>
    );
};

export default ProposalTabs;
