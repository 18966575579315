import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosHelper/axios';
import FilterBar from './FilterBar/FilterBar';
import ProposalCard from './ProposalCard';
import PaginationBar from '../paginationBar/paginationBar';
import useFilter from '../../../utils/filterContext';
import useData from '../../../utils/dataContext';
import { Rings } from 'react-loader-spinner';
// import { getPlanetIdFromName } from '../../../utils/functions'

//ASSETS
import profile from '../../../images/profile.svg';
import './Proposal.css';

interface Choice {
    position: number;
    name: string;
}

interface VoteTotals {
    0?: number;
    1?: number;
    2?: number;
}

interface ProposalListItem {
    author: string;
    choicesArray: Choice[];
    // commentTotal: any;
    comments: Object[];
    description: string;
    planetDoc: string;
    postStatus: string; // enum
    sig: string;
    status: string; // enum
    tags: string[];
    title: string;
    updatedAt: string; // date string
    updates: Object[];
    votingDurationHours: number;
    votes?: VoteTotals;
}

type ProposalProps = {
    isHolder: boolean;
};

const Proposal: React.FC<ProposalProps> = ({ isHolder }) => {
    const [isLoading, setIsLoading] = useState<boolean>();
    const [planetProposals, setPlanetProposals] = useState<ProposalListItem[]>([]);

    const { 
        statusOption, 
        proposalOption, 
        tagOption,
        pageNumber,
        setPageNumber
    } = useFilter();

    const {
        planetsNames
    } = useData();
    
    useEffect(() => {
        getPlanetProposals(
            planetsNames,
            pageNumber,
            statusOption.value,
            proposalOption.value,
            tagOption.value
        );
    }, [pageNumber, statusOption, proposalOption, tagOption, planetsNames]);

    const getVoteObject = (proposalVoteObj: VoteTotals) => {
        const votes = { for: 0, against: 0, abstain: 0 };
        if (proposalVoteObj && proposalVoteObj[0]) {
            votes.for = proposalVoteObj[0];
        }
        if (proposalVoteObj && proposalVoteObj[1]) {
            votes.against = proposalVoteObj[1];
        }
        if (proposalVoteObj && proposalVoteObj[2]) {
            votes.abstain = proposalVoteObj[2];
        }
        return votes;
    };

    const getPlanetProposals = async (
        name_string: string,
        currPageNum: number,
        statusOption: string,
        proposalOption: string,
        tagOption: string
    ) => {
        setIsLoading(true);
        // console.log('page number: ', currPageNum)
        if (proposalOption === 'endAt' && statusOption === 'All') {
            //gets all proposals that do not have status as closed
            await axiosInstance
                .get(
                    `proposals/?sortBy=endAt&sortDir=asc&status=Active&status=Draft&status=Quorum&planet=${name_string}&pageSize=10&page=${currPageNum}`
                )
                .then((res) => {
                    const allPlanetProposals = res.data;
                    setPlanetProposals(allPlanetProposals);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            let proposalParameters = {
                params: {
                    planet: name_string,
                    pageSize: 10,
                    page: currPageNum,
                    sortDir: proposalOption === 'endAt' ? 'desc' : 'desc',
                    sortBy:
                        proposalOption === 'endAt' ? 'endAt' : proposalOption,
                    status: statusOption === 'All' ? null : statusOption,
                    tags: tagOption === 'All' ? null : tagOption,
                },
            };
            await axiosInstance
                .get('proposals/', proposalParameters)
                .then((res) => {
                    const allPlanetProposals = res.data;
                    setPlanetProposals(allPlanetProposals);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <div id="proposal-section">
            <div id="proposal-container">
                <FilterBar
                    isHolder={isHolder}
                />
                <div className="proposal-scroll">
                    {isLoading ? (
                        <div className="loading-vote">
                            <Rings
                                height={75}
                                color="#fff"
                                ariaLabel="loading-indicator"
                            />
                            Loading proposals
                        </div>
                    ) : (
                        <>
                            {planetProposals.map(
                                (proposal: any, index: number) => (
                                    <ProposalCard
                                        key={index}
                                        planet_name={proposal.planet}
                                        proposal_id={proposal._id}
                                        middlePadding={true}
                                        creatorAddress={
                                            proposal.authorSignerAddress
                                        }
                                        creatorImg={profile}
                                        createdAt={proposal.createdAt}
                                        expiresAt={proposal.endAt}
                                        title={proposal.title}
                                        description={proposal.description}
                                        tags={proposal.tags}
                                        status={proposal.status}
                                        votes={getVoteObject(proposal.votes)}
                                        updaoPlanetsState={proposal.updaoStatus}
                                        reachedQuorumAmount={Object.values(
                                            proposal.updaoStatus
                                        )}
                                        updaoVoteTotals={
                                            proposal.updaoVoteTotals
                                        }
                                        finalUpdaoVotes={
                                            proposal.finalUpdaoVotes?proposal.finalUpdaoVotes:null
                                        }
                                        propVoteStatus={proposal.postStatus}
                                        quorumMet={proposal.quorumMet}
                                    />
                                )
                            )}
                            {planetProposals.length >= 10 || pageNumber !== 1 ? (
                                <PaginationBar
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}
                                />
                            ) : null}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Proposal;
