import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DataProvider } from './utils/dataContext';
import { FilterProvider } from './utils/filterContext';
import { Web3ReactProvider, Web3ReactHooks } from '@web3-react/core'
import type { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import type { MetaMask } from '@web3-react/metamask'
import type { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import { Web3Provider } from "@ethersproject/providers";
import { coinbaseWalletConnector, coinbaseHooks, metamaskConnector, metamaskHooks, walletConnectV2Connector, walletConnectV2Hooks } from "./utils/connectors"

const connectors: [MetaMask | WalletConnectV2 | CoinbaseWallet, Web3ReactHooks][] = [
  [metamaskConnector, metamaskHooks],
  [walletConnectV2Connector, walletConnectV2Hooks],
  [coinbaseWalletConnector, coinbaseHooks],
]

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Web3ReactProvider connectors={connectors}>
      <DataProvider>
        <FilterProvider>
        <Router>
          <App />
        </Router>          
        </FilterProvider>
      </DataProvider>
    </Web3ReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
