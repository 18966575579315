import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { InjectedConnector } from "@web3-react/injected-connector";
import { initializeConnector } from '@web3-react/core'
import { MetaMask } from '@web3-react/metamask'
import { IS_MAINNET } from './allplanetaryData';

export const infuraKey = process.env.REACT_APP_INFURA_KEY || ''

export const walletConnectKey = process.env.REACT_APP_WALLETCONNECT_KEY || ''
console.log(walletConnectKey === '')
console.log(infuraKey === '')
export const [coinbaseWalletConnector, coinbaseHooks] = initializeConnector<CoinbaseWallet>(
  (actions) => new CoinbaseWallet({
    actions,
    options: {
      url: IS_MAINNET ? `https://mainnet.infura.io/v3/${infuraKey}` : `https://goerli.infura.io/v3/${infuraKey}`,
      appName: "UPDAO",
    }
  })
)

export const [walletConnectV2Connector, walletConnectV2Hooks] = initializeConnector<WalletConnectV2>(
  (actions) => new WalletConnectV2({
    actions,
    options: {
      projectId: walletConnectKey,
      chains: IS_MAINNET ? [1] : [5],
      showQrModal: true
    }
  })
)

export const [metamaskConnector, metamaskHooks] = initializeConnector<MetaMask>((actions) => new MetaMask({ actions }))
