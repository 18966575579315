import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import "./connectButtonComponent.css";
import pfp from "../../images/pfp.svg";
import walletImage from "../../images/wallet.svg";
import coinbaseLogo from "../../images/coinbase-icon.svg";
import walletConnectLogo from "../../images/walletconnect-logo.webp";
import metamaskLogo from "../../images/metamask.webp";
import { coinbaseWalletConnector, coinbaseHooks, metamaskConnector, metamaskHooks, walletConnectV2Connector, walletConnectV2Hooks } from "../../utils/connectors"


type ButtonProps = {
  widgetPadding: boolean;
};

export const ConnectButtonSecond: React.FC<ButtonProps> = (props) => {
  const { widgetPadding } = props;
  // const [ethAddress, setEthAddress] = useState("Connect Wallet");
  const ethAddress = "Connect Wallet"
  const { isActive, account, connector } = useWeb3React<Web3Provider>();
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  
  async function connect(wallet: string) {
    if(wallet === 'Metamask'){
      await metamaskConnector.activate()
      localStorage.setItem('isMetamaskConnected', String(true))
    }
    else if(wallet === 'Coinbase'){
      console.log('coinbase');
     await coinbaseWalletConnector.activate();
      localStorage.setItem('isCoinbaseConnected', String(true))
    }
    else if(wallet === 'WalletConnect'){
     await walletConnectV2Connector.activate();
      localStorage.setItem('isConnectConnected', String(true))
    }
  }

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem('isMetamaskConnected') === 'true') {
          await metamaskConnector.activate()
      }
      else if(localStorage?.getItem('isCoinbaseConnected') === 'true'){
        await coinbaseWalletConnector.activate();
      }
      else if(localStorage?.getItem('isConnectConnected') === 'true'){
        await walletConnectV2Connector.activate();
      }
    }
    connectWalletOnPageLoad()
  }, [])

  return isActive ? (
    <span className="connect-active">
      <img alt="Profile" src={pfp} width="18" height="18" />
      <span>{account}</span>
    </span>
  ) : (
    <div className="dialog-container">
      {showDialog ? (
        <div className="create-connectmodal-background">
          <div className="create-connect-modal">
            <span className="closeX" onClick={close}>
              X
            </span>
            <div>
              <p className="select">Select Wallet</p>
              <div className="connect-buttons">
                <button
                  className="btn"
                  onClick={() => connect('Coinbase')
                    // {activate(connectors.CoinbaseWallet);}
                  }
                >
                  <img
                    width={20}
                    height={20}
                    src={coinbaseLogo}
                    alt="wallet-logo"
                  />
                  <span className="btn-text"> Coinbase Wallet </span>
                </button>
                <button
                  className="btn-wc"
                  onClick={() => connect('WalletConnect')}
                >
                  <img
                    width={20}
                    height={20}
                    src={walletConnectLogo}
                    alt="wallet-logo"
                  />
                  <span className="btn-text">Wallet Connect</span>
                </button>
                <button
                  className="btn-meta"
                  onClick={() => connect('Metamask')}
                >
                  <img
                    width={20}
                    height={20}
                    src={metamaskLogo}
                    alt="wallet-logo"
                  />
                  <span className="btn-text">Metamask</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <button
        onClick={open}
        className={`button ${
          widgetPadding ? "widget-padding" : "header-padding"
        }`}
      >
        <span className="inactive">
          <img alt="Profile" src={walletImage} width="18" height="18" />
          {ethAddress}
        </span>
      </button>
    </div>
  );
};
