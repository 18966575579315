import React, { useEffect, useState } from 'react';
import { VoteState } from './voteState';
import { AbstainVote } from './voteButton';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import axiosInstance from '../../../../../axiosHelper/axios';
import { useParams } from 'react-router-dom';
import useData from '../../../../../utils/dataContext';
import { generateSignedPayload } from '../../../../../utils/functions';
import ErrorComponent from '../../../../ErrorComponent/ErrorComponent';

//ASSETS
// import infoIcon from '../../../../../images/info.svg';
import { ReactComponent as Thumbsup } from '../../../../../images/GreenThumbsup.svg';
import { ReactComponent as Thumbsdown } from '../../../../../images/RedThumbsdown.svg';
import { ReactComponent as AbstainSVG } from '../../../../../images/abstain.svg';
import { Rings } from 'react-loader-spinner';
import thumbsupblack from '../../../../../images/BlackThumbsup.svg';
import thumbsdownblack from '../../../../../images/BlackThumbsdown.svg';
import './voteWidget.css';

// interface Proposal {
//     // proposal: {
//     author: string;
//     createdAt: string;
//     title: string;
//     description: string;
//     // }
// }

interface ExistingVote {
    accountId: string;
    createdAt: string; // date string
    proposalId: string;
    tokensVoting: number;
    updatedAt: string; // date string
    votes?: ExistingVote[];
    votingFor?: number; // updao vote
    voteChoicesArray: Object[];
}

type voteProps = {
    currentVotes: number;
    planetId: number;
    status?: string;
    propid: string | undefined;
    proposalDetails?: any;
    updaoPlanetBalArr: any[];
    updaoFDBalArr: any[];
    foundersBal: number;
    blockNumber: number;
    fetchNewDetails: Function;
    votes: any;
    postStatus: string;
};

type UpdaoVoteObj = {
    tokensVoting: number; // number of votes
    votingFor: number; // which vote option this vote is voting for
    votingTokenId: number; // tokenId of the token being used for this vote  (ex: Venus=1)
    valueString: string;
};

type FoundersDAOVoteObj = {
    tokensVoting: number; // number of tokens being used to vote
    votingFor: number; // which vote option this vote is voting for
    valueString: string; // value string shown for this vote on the FE
    votingTokenId: number[];
};

interface FullUpdaoVote {
    [key: number]: UpdaoVoteObj;
    FD?: FoundersDAOVoteObj;
}

type UpdaoVoteRequest = {
    signerAddress: string;
    accountId: string;
    proposalId: string;
    updaoVotes: FullUpdaoVote;
};

const VoteWidget: React.FC<voteProps> = (props) => {
    const {
        currentVotes,
        status,
        propid,
        fetchNewDetails,
        postStatus,
        planetId,
        updaoPlanetBalArr,
        updaoFDBalArr,
        foundersBal,
        // blockNumber,
        votes,
    } = props;
    const { updaoAccount } = useData();
    const [existingVote, setExistingVote] = useState<ExistingVote>();
    const [foundError, setFoundError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [widgetState, setWidgetState] = useState('Loading');
    const { isActive, account, connector } = useWeb3React<Web3Provider>();
    const { provider } = connector
    const [majorityVote, setMajorityVote] = useState<string>();

    let yesVotes = votes?.[0] ? votes?.[0] : 0;
    let noVotes = votes?.[1] ? votes?.[1] : 0;
    let abstainVotes = votes?.[2] ? votes?.[2] : 0;

    let params = useParams();
    let planetname = params.planet_name;
    let providerInstance = isActive && provider ? provider : undefined;


    useEffect(() => {
        const getUserVote = async (accountId: string, proposalId: string) => {
            const res = await axiosInstance
                .get(`/vote/?proposalId=${proposalId}&accountId=${accountId}`)
                .then((res) => {
                    if (res.data.votes.length) {
                        setExistingVote(res.data.votes[0]);
                    }
                })
                .catch((err) => {
                    console.error('getVotesErr', err);
                });
            return res;
        };

        const getUserUPDAOVote = async (
            accountId: string,
            proposalId: string
        ) => {
            const res = await axiosInstance
                .get(`/updao/proposal/${proposalId}/votes/${accountId}`)
                .then((res) => {
                    if (
                        res.data.length &&
                        res.data[0].votes &&
                        res.data[0].votes.length
                    ) {
                        setExistingVote(res.data[0].votes[0]);
                        console.log(existingVote);
                    }
                })
                .catch((err) => {
                    console.error('getVotesErr', err);
                });
            return res;
        };

        if (updaoAccount && propid) {
            planetname === 'UPDAO'
                ? getUserUPDAOVote(updaoAccount._id, propid)
                : getUserVote(updaoAccount._id, propid);
        }
    }, [updaoAccount, propid]);

    useEffect(() => {
        if (existingVote && planetname === 'UPDAO') {
            changeVoteColor(existingVote?.votingFor?.toString());
        } else if (
            existingVote &&
            existingVote?.votes?.length &&
            existingVote.votes[0].votingFor
        ) {
            changeVoteColor(existingVote?.votes[0].votingFor.toString());
        } else {
            console.error('error finding vote selection');
        }
    }, [isActive, existingVote]);

    useEffect(() => {
        if (!existingVote) {
            if (currentVotes > 0) {
                setWidgetState('Allowed');
            } else if (currentVotes < 0) {
                setWidgetState('Loading');
            } else if (currentVotes === 0) {
                setWidgetState('Denied');
            }
        } else {
            setWidgetState('Existing');
        }
    }, [currentVotes]);

    const changeVoteColor = (votingChoice: string | undefined) => {
        if (!votingChoice) return;
        renderVoteDecision(votingChoice);
    };

    const getVotingForValueFromVotingChoice = (votingChoice: string) => {
        if (votingChoice === 'Yes') {
            return 0;
        } else if (votingChoice === 'No') {
            return 1;
        } else {
            return 2;
        }
    };

    useEffect(() => {
        if (yesVotes > noVotes && yesVotes > abstainVotes) {
            setMajorityVote('Yes');
        } else if (noVotes > yesVotes && noVotes > abstainVotes) {
            setMajorityVote('No');
        } else {
            setMajorityVote('Abstain');
        }
    }, [votes]);

    const handleVoteClick = async (votingChoice: string) => {
        try {
            setIsLoading(true);
            let response, signature;
            let msgBody = {
                accountId: updaoAccount._id,
                proposalId: propid,
                tokensVoting: currentVotes,
                signerAddress: account,
                votingFor: getVotingForValueFromVotingChoice(votingChoice),
                votingTokenId: planetId,
                valueString: votingChoice,
            };

            signature = await generateSignedPayload(providerInstance, msgBody);
            response = await postVotes(signature, msgBody);

            if (
                response &&
                response.voteSubmit &&
                response.voteSubmit.status &&
                signature
            ) {
                setExistingVote(
                    response.voteSubmit.voteResult || response.voteSubmit
                );
                setWidgetState('Existing');
                await fetchNewDetails(planetname, 1);
            } else {
                setFoundError(true);
            }
        } catch (error: any) {
            setFoundError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUPDAOVoteClick = async (votingChoice: string) => {
        try {
            setIsLoading(true);
            let response, signature;
            let msgBody: UpdaoVoteRequest = {
                signerAddress: account || '',
                accountId: updaoAccount._id,
                proposalId: propid || '',
                updaoVotes: {},
            };
            const voteOption = getVotingForValueFromVotingChoice(votingChoice);
            const updaoVoteObjs = updaoPlanetBalArr.map((planetBalance) => {
                if (planetBalance) {
                    return {
                        tokensVoting: planetBalance.amount,
                        votingFor: voteOption,
                        votingTokenId: planetBalance.identifier, // account for moon/dark moon
                        valueString: votingChoice,
                    };
                }
            });
            updaoVoteObjs.forEach((el: UpdaoVoteObj | undefined) => {
                if (el) {
                    msgBody.updaoVotes[el.votingTokenId] = el;
                }
            });
            if (foundersBal > 0) {
                msgBody.updaoVotes.FD = {
                    tokensVoting: foundersBal,
                    votingFor: voteOption,
                    votingTokenId: updaoFDBalArr, // array of founders dao tokenids
                    valueString: votingChoice,
                };
            }

            signature = await generateSignedPayload(providerInstance, msgBody);
            response = await postUPDAOVotes(signature, msgBody);

            if (response && response.voteStatus && signature) {
                setExistingVote(response.submittedVote.votes[0]);
                setWidgetState('Existing');
                await fetchNewDetails(planetname, 1);
            } else {
                setFoundError(true);
            }
        } catch (error: any) {
            console.log(error);
            setFoundError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const postVotes = async (signature: any, body: any = {}) => {
        const res = await axiosInstance
            .post('/vote/submit', {
                msgBody: body,
                signature: signature,
            })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.error(err);
                setFoundError(true);
            });
        return res;
    };

    const postUPDAOVotes = async (signature: any, body: any = {}) => {
        const res = await axiosInstance
            .post('/updao/votes/submit', {
                msgBody: body,
                signature: signature,
            })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.error(err);
                setFoundError(true);
            });
        return res;
    };

    const renderVoteDecision = (voteChoice: string) => {
        switch (voteChoice) {
            case 'Yes':
            case '0':
                return (
                    <div className="vote-decision-cont">
                        <Thumbsup className="vote-decision-icon" />
                        <div className="vote-decision">
                            <h2>
                                You Voted: {voteChoice === '0' ? 'Yes' : 'Yes'}
                            </h2>
                            <small>You cannot change your vote</small>
                        </div>
                    </div>
                );
            case 'No':
            case '1':
                return (
                    <div className="vote-decision-cont">
                        <Thumbsdown className="vote-decision-icon" />
                        <div className="vote-decision">
                            <h2>
                                You Voted: {voteChoice === '1' ? 'No' : 'No'}
                            </h2>
                            <small>You cannot change your vote</small>
                        </div>
                    </div>
                );
            case 'Abstain':
            case '2':
                return (
                    <div className="vote-decision-cont">
                        <AbstainSVG
                            className="vote-decision-icon"
                            fill="#F8B83C"
                        />
                        <div className="vote-decision">
                            <h2>
                                You Voted:{' '}
                                {voteChoice === '2' ? 'Abstain' : 'Abstain'}
                            </h2>
                            <small>You cannot change your vote</small>
                        </div>
                    </div>
                );
            default:
                return;
        }
    };

    const renderWidgetState = (state: string) => {
        switch (state) {
            case 'Loading':
                return (
                    <div className="loading-vote">
                        <Rings
                            height={75}
                            color="#fff"
                            ariaLabel="loading-indicator"
                        />
                        Loading vote data
                    </div>
                );
            case 'Allowed':
                return (
                    <>
                        <div className="vote-flex-container">
                            <h2>
                                Cast Your {currentVotes}{' '}
                                {currentVotes > 1 ? 'Votes' : 'Vote'}
                            </h2>
                            <small className="sm-text">
                                Number of votes based on your holdings at the
                                time the proposal was created.
                            </small>
                        </div>
                        <div className="buttons-container">
                            <button
                                className="vote-button"
                                onClick={() =>
                                    planetname !== 'UPDAO'
                                        ? handleVoteClick('Yes')
                                        : handleUPDAOVoteClick('Yes')
                                }
                            >
                                <span>
                                    <img
                                        style={{
                                            paddingRight: '5px',
                                            cursor: 'pointer',
                                        }}
                                        src={thumbsupblack}
                                        alt="thumbs action"
                                    />
                                    <span className="vote-button-text">
                                        Yes
                                    </span>
                                </span>
                            </button>
                            <button
                                className="vote-button"
                                onClick={() =>
                                    planetname !== 'UPDAO'
                                        ? handleVoteClick('No')
                                        : handleUPDAOVoteClick('No')
                                }
                            >
                                <span>
                                    <img
                                        style={{
                                            paddingRight: '5px',
                                            cursor: 'pointer',
                                        }}
                                        src={thumbsdownblack}
                                        alt="thumbs action"
                                    />
                                    <span className="vote-button-text">No</span>
                                </span>
                            </button>
                            <AbstainVote
                                onClick={() =>
                                    planetname !== 'UPDAO'
                                        ? handleVoteClick('Abstain')
                                        : handleUPDAOVoteClick('Abstain')
                                }
                            />
                            {foundError ? (
                                <ErrorComponent
                                    errorText="Error. Something went wrong with submitting your vote. Try again."
                                    color="#dd3434"
                                />
                            ) : (
                                <ErrorComponent
                                    errorText="You cannot change your vote once
                                submitted."
                                    color="#f8b83c"
                                />
                            )}
                        </div>
                    </>
                );
            case 'Existing':
                return renderVoteDecision(existingVote!.votingFor!.toString());
            case 'Denied':
                return (
                    <div className="cannot-vote-cont">
                        <h2>You cannot vote on this proposal</h2>
                        <small>
                            At the time this proposal was created, you did not
                            hold any voting tokens.
                        </small>
                    </div>
                );
        }
    };

    return (
        <div className="card">
            {status !== 'Closed' ? (
                <>
                    {isLoading ? (
                        <div className="loading-vote">
                            <Rings
                                height={75}
                                color="#fff"
                                ariaLabel="loading-indicator"
                            />
                            Submitting your vote
                        </div>
                    ) : (
                        <>
                            {!updaoAccount ? (
                                <div className="cannot-vote-cont">
                                    <h2>Connect your wallet</h2>
                                    <small>
                                        To vote, connect a wallet holding a
                                        voting token at the time this proposal
                                        was created.
                                    </small>
                                </div>
                            ) : (
                                renderWidgetState(widgetState)
                            )}
                        </>
                    )}
                </>
            ) : (
                <VoteState
                    majority={majorityVote === 'Yes' ? 'Pending' : majorityVote}
                    postStatus={postStatus}
                />
            )}
        </div>
    );
};

export default VoteWidget;
