import React, { useState } from 'react';
import ProposalModal from '../CreateProposalModal/ProposalModal';
import StatusFilter from './StatusFilter';
import ProposalFilter from './ProposalFilter';
import TagFilter from './TagFilter';
import useFilter from '../../../../utils/filterContext';
import { useWeb3React } from "@web3-react/core";


//ASSETS
import { ReactComponent as FilterButton } from '../../../../images/filterButton.svg';
import "./FilterBar.css";
import { Web3Provider } from '@ethersproject/providers';

interface FilterBarProps {
  isHolder:boolean;
}

const FilterBar: React.FC<FilterBarProps> = ({ 
  isHolder 
  }) => {
  const { isActive, account } = useWeb3React<Web3Provider>();

  const { tagOptions } = useFilter();
  const [modal, setModal] = useState<boolean>(false);
  const [ filterDropDown, setFilterDropDown ] = useState<boolean>(false);
  const screenWidth = window.innerWidth;

  const handleModal = (modalstatus:boolean) => {
    (!modalstatus) ? setModal(true) : setModal(false)
  }

  const DisplayProposalCreateButton = (canVote:boolean) => {
    return (canVote)
    ?
    <button 
      className={(canVote? "create-proposal-button":"create-proposal-button-inactive")}
      onClick={() => handleModal(modal)}>
      <span className="create-proposal-plus">+</span> Create Proposal
    </button>
    : null
  }

  if(screenWidth > 600){
    //DESKTOP VIEW
    return (
      <div className="filter-bar-container">
        <div className="fbc-left">
          <span className="fbc-text">Filter by: </span>
            <StatusFilter
              mobile={false}
            />
            <ProposalFilter 
              mobile={false}
            />
            <TagFilter
              mobile={false}
            />
        </div>
        <ProposalModal 
          account_id={(isActive) ? account : null} 
          status={modal} 
          modalTagOptions={tagOptions}
          handleModal={handleModal} 
          // handleProposalSubmit={handleProposalSubmit}
        />
        { DisplayProposalCreateButton(isHolder)}
      </div>
    );    
  } else {
    //MOBILE VIEW
    return (
      <div className="filter-bar-container">
          <div className='filter-button' onClick={() => setFilterDropDown(!filterDropDown)}>
            <div>
              <FilterButton/> 
            </div>      
            <div>
              Filter
            </div>
          </div>
          <div className={filterDropDown?"filter-bar-bg":"filter-bar-hide"}>
            <StatusFilter
              mobile={true}
            />
            <ProposalFilter
              mobile={true}
            />
            <TagFilter
              mobile={true}
            />
          </div>
          <ProposalModal 
            account_id={(isActive) ? account : null} 
            status={modal} 
            modalTagOptions={tagOptions}
            handleModal={handleModal} 
          />
        {DisplayProposalCreateButton(isHolder)}
      </div>
    );
  }

};

export default FilterBar;
